// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Antic+Didone&family=Raleway:wght@100;200;300;400;700&family=Tenor+Sans&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body,
#root{
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  width: 100%;
  height: 100%;
  margin-top: 0;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}

body {
  overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;;;EAGE,kCAAkC;EAClC,gBAAgB;EAChB,WAAW;EACX,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;;AAExB;;AAEA;EACE,cAAc;AAChB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Antic+Didone&family=Raleway:wght@100;200;300;400;700&family=Tenor+Sans&display=swap');\n\nhtml,\nbody,\n#root{\n  font-family: \"Raleway\", sans-serif;\n  font-weight: 500;\n  width: 100%;\n  height: 100%;\n  margin-top: 0;\n}\n\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  \n}\n\nbody {\n  overflow: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
