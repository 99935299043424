// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* GENERAL */
/* THEME LIGHT */
/* THEME DARK */
/* TYPOGRAPHY */
.friend-container {
  gap: 1rem;
}

.friend-name:hover {
  color: #986A3E;
  cursor: pointer;
}

.friend-subtitle {
  font-size: 0.75rem;
}

.fa-sm {
  margin-left: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/scss/config.scss","webpack://./src/components/Friend/Friend.scss"],"names":[],"mappings":"AAAA,YAAA;AAKA,gBAAA;AAUA,eAAA;AAWA,eAAA;ACxBA;EACI,SAAA;AAGJ;;AAGI;EACI,cDPI;ECQJ,eAAA;AAAR;;AAIA;EACI,kBAAA;AADJ;;AAIA;EACI,iBAAA;AADJ","sourcesContent":["/* GENERAL */\n\n$bg-image: url('../assets/background.jpg');\n$bg-button: #986A3E;\n\n/* THEME LIGHT */\n$bg-overlay-light: rgba(255, 255, 255, 0.4);\n$bg-header-light: rgba(158, 152, 146, 0.6);\n$bg-menu-light: rgba(207, 201, 196, 0.85);\n$bg-widget-light: rgba(255, 255, 255, 0.8);\n$typography-light: #3B281A;\n$bg-button-hover-light: #6A4B2D;\n$outline-light: #8c7e71;\n$input-hover-light: #E4E0DB;\n\n/* THEME DARK */\n$bg-overlay-dark: rgba(0, 0, 0, 0.25);\n$bg-header-dark: rgba(63, 56, 53, 0.9);\n$bg-menu-dark: rgba(102, 97, 93, 0.85);\n$bg-widget-dark: rgba(18, 12, 8, 0.8);\n$bg-input-dark: rgba(72, 71, 70, 0.6);\n$typography-dark: #FAFAFA;\n$bg-button-hover-dark: #C69362;\n$outline-dark: #BCB5AD;\n$input-hover-dark: #66615D;\n\n/* TYPOGRAPHY */\n$font-primary: 'Raleway', sans-serif;\n$font-secondary: 'Tenor Sans', sans-serif;\n$font-title: 'Antic Didone', serif;\n\n","@import '../../scss/config.scss';\n\n.friend-container {\n    gap: 1rem;\n}\n\n.friend-name {\n    \n\n    &:hover {\n        color: $bg-button;\n        cursor: pointer;\n    }\n}\n\n.friend-subtitle {\n    font-size: 0.75rem;\n}\n\n.fa-sm {\n    margin-left: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
