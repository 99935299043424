// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-page-container {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  width: 100%;
  padding: 8rem 6%;
}
.home-page-container .home-user-widget {
  flex-basis: 26%;
}
.home-page-container .home-ad-widget {
  flex-basis: 26%;
}
.home-page-container .home-posts-widget {
  flex-basis: 42%;
}
.home-page-container .home-friends-widget {
  margin: 2rem 0;
}

@media (max-width: 1000px) {
  .home-page-container {
    display: block;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/homePage/HomePage.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,WAAA;EACA,WAAA;EACA,gBAAA;AACJ;AACI;EACI,eAAA;AACR;AAEI;EACI,eAAA;AAAR;AAGI;EACI,eAAA;AADR;AAII;EACI,cAAA;AAFR;;AAMA;EACI;IACI,cAAA;EAHN;AACF","sourcesContent":[".home-page-container {\n    display: flex;\n    justify-content: space-between;\n    gap: 0.5rem;\n    width: 100%;\n    padding: 8rem 6%;\n\n    .home-user-widget {\n        flex-basis: 26%;\n    }\n    \n    .home-ad-widget {\n        flex-basis: 26%;\n    }\n    \n    .home-posts-widget {\n        flex-basis: 42%;\n    }\n    \n    .home-friends-widget {\n        margin: 2rem 0;\n    }\n}\n\n@media (max-width: 1000px) {\n    .home-page-container {\n        display: block;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
