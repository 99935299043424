
const UserImage = ({ image, size = "60px" }) => {
    return (
        <div width={size} height={size}>
            <img
                style={{ objectFit: "cover", borderRadius: "50%" }}
                width={size}
                height={size}
                alt="user"
                src={`//decarto.olyafedykovych.com/assets/${image}`}
            />
        </div>
    );
};

export default UserImage;