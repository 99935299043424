// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-page-container {
  width: 100%;
  padding: 8rem 6%;
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.user-widget-profile {
  flex-basis: 26%;
}

.friend-list-widget-profile,
.posts-widget-profile {
  margin: 2rem 0;
}

.my-post-widget-profile {
  flex-basis: 42%;
  margin-top: 0;
}

@media (max-width: 1000px) {
  .profile-page-container {
    display: block;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/profilePage/ProfilePage.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,SAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;;EAEI,cAAA;AACJ;;AAEA;EACI,eAAA;EACA,aAAA;AACJ;;AAEA;EACI;IACI,cAAA;EACN;AACF","sourcesContent":[".profile-page-container {\n    width: 100%;\n    padding: 8rem 6%;\n    display: flex;\n    justify-content: center;\n    gap: 2rem;\n}\n\n.user-widget-profile {\n    flex-basis: 26%;\n}\n\n.friend-list-widget-profile,\n.posts-widget-profile {\n    margin: 2rem 0;\n}\n\n.my-post-widget-profile {\n    flex-basis: 42%;\n    margin-top: 0;\n}\n\n@media (max-width: 1000px) {\n    .profile-page-container {\n        display: block;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
